<template>
  <div class="app">
    <script
      v-html="jsonld"
      type="application/ld+json"
    ></script>
    <messageButton v-if="messageButtons.length" :data="messageButtons">
      <template #tooltip>{{ $t('dragging_move') }}</template>
    </messageButton>
    <div v-if="isSuper" class="bg-red text-white text-center p-8">SUPER MODE</div>
    <hotelNav class="hotel-navbar"></hotelNav>
    <div
      v-if='$route.meta.cover !== false'
      ref="hotelCover"
      class="hotel-cover hotel__cover"
      :class="{'z-index-up': isLoadingRooms, 'show': hotelCoverShow }">
      <imagesSplitter @clickImage="openPhotoSwipe" :photos="coverImages"></imagesSplitter>
      <photoSwipe ref="photoSwipe" :photos="coverImages" @close="photoSwipeCloseHandler"></photoSwipe>
    </div>

    <div v-if="!isLoadingHotel" class="hotel-main main">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </div>

    <!-- footer -->
    <footer
      v-if='isInitDone'
      :class='{"mobile-has-order": displayFooterMarginBottom}'
      class='hotel-footer bg-grey-lightest py-24'>
      <div class="container text-grey-dark">
        <div class="footer__hotel">
          <div class="footer__hotel-name flex flex-col sm:flex-row items-center justify-between border-b-1 border-grey-light pb-16">
            <h3 class='text-grey-darker font-medium mb-8 sm:mb-0'>{{ hotelHotel.name }}</h3>
          </div>
          <div class="footer__contacts flex flex-col py-24 text-sm">
            <a
              :href='`http://maps.google.com/maps?q=${hotelHotel.address}`'
              target='_blank'
              class="footer__contacts__way mb-16 lg:mb-0">
              <i class="owl-location"></i>
              <span>{{ hotelHotel.address }}</span>
            </a>
            <a
              v-if="hotelDisplaySetting.showPhone"
              :href='`tel:${hotelHotel.contact.phone}`'
              class="footer__contacts__way mb-16 lg:mb-0">
              <i class="owl-phone"></i>
              <span>{{ hotelHotel.contact.phone }}</span>
            </a>
            <a
              v-if="hotelHotelWebSiteUrl && hotelDisplaySetting.showLink"
              :href="hotelHotelWebSiteUrl"
              target="_blank"
              class="footer__contacts__way mb-16 lg:mb-0">
              <i class="owl-earth"></i>
              <span>{{ hotelHotel.name }}</span>
            </a>
            <div
              v-if="hotelHotel.contact.vat"
              class="footer__contacts__way my-16 ml-4 lg:mb-0">
              <i class="owl-card-member"></i>
              <span>{{ `${$t('vat')}：${hotelHotel.contact.vat}` }}</span>
            </div>
          </div>
        </div>
        <span v-if="isOwlTingDomain" class='block text-center sm:text-right'>
          Powered by <a href="https://www.owlting.com/owlnest" target="_blank" class="text-blue font-bold no-underline">{{ $t('footerPowerBy') }}</a>
        </span>
      </div>
    </footer>
    <v-dialog :clickToClose="false" />
    <modals-container />
    <cookieNotification />
    <devTool v-if="isSuper" />
    <cookieNotification />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import hotelNav from '@/components/hotelNav'
import devTool from '@/components/devTool'
import imagesSplitter from '@/components/imagesSplitter'
import cookieNotification from '@/components/cookieNotification'
import photoSwipe from '@/components/photoSwipe'
import _ from 'lodash'
import fbChatSet from '@/assets/facebookChat.js'
import messageButton from '@/components/messageButton.vue'

const $images = require.context('./', true, /\.jpg$|\.png$|\.svg$/)

export default {
  name: 'App',
  metaInfo () {
    const hotelName = this.hotelHotel.name
    return {
      title: hotelName,
      titleTemplate: (titleChunk) => {
        return this.metaTitle()
      },
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}/${this.$route.params.hotelId}`
        },
        {
          rel: 'alternate',
          type: 'text/html',
          hreflang: 'zh-tw',
          href: `${window.location.origin}/${this.$route.params.hotelId}?lang=zh_TW`
        },
        {
          rel: 'alternate',
          type: 'text/html',
          hreflang: 'en',
          href: `${window.location.origin}/${this.$route.params.hotelId}?lang=en`
        },
        {
          rel: 'alternate',
          type: 'text/html',
          hreflang: 'ja',
          href: `${window.location.origin}/${this.$route.params.hotelId}?lang=ja`
        }
      ],
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          'property': 'og:url',
          'content': this.ogUrl,
          'vmid': 'og:url'
        },
        {
          'property': 'og:title',
          'template': this.metaTitle(),
          'vmid': 'og:title'
        },
        {
          'property': 'og:image',
          'content': `${this.metaImagePath}?width=1200&height=630`,
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:width',
          'content': '1200',
          'vmid': 'og:image:width'
        },
        {
          'property': 'og:image:height',
          'content': '630',
          'vmid': 'og:image:height'
        },
        {
          'property': 'og:type',
          'content': 'website',
          'vmid': 'og:type'
        },
        {
          'property': 'og:site_name',
          // 'template': chunk => chunk ? `${chunk} - ${hotelName} | OwlNest` : `${hotelName} | OwlNest`,
          'template': this.metaTitle(),
          'vmid': 'og:site_name'
        },
        {
          'property': 'og:description',
          'content': this.metaDescription(),
          'vmid': 'og:description'
        },
        {
          'property': 'description',
          'content': this.metaDescription()
        }
      ],
      htmlAttrs: {
        lang: this['selectedLanguage']
      }
    }
  },
  components: {
    hotelNav,
    imagesSplitter,
    photoSwipe,
    devTool,
    cookieNotification,
    messageButton
  },
  data () {
    return {
      messageButtons: [],
      hotelCoverShow: false
    }
  },
  watch: {
    // close modals while routing change
    '$route' (to, from) {
      this.$modal.hide('mobile-orders')
      this.$modal.hide('features-modal')
      this.$modal.hide('mobile-payment-total')
    },
    windowWidth (val, newVal) {
      if (newVal > 991) {
        this.$modal.hide('mobile-orders')
        this.$modal.hide('mobile-payment-total')
      }
    },
    selectedLanguage: {
      handler (value) {
        this.$dayjs.locale(this.dayjsLangParse)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      isLoadingRooms: 'hotel/isLoadingRooms',
      selectedLanguage: 'selectedLanguage',
      hotelHotel: 'hotel/hotel',
      step: 'step',
      isSuper: 'isSuper',
      windowWidth: 'windowWidth',
      orderPlans: 'order/plans',
      orderAddons: 'order/addons',
      hasAgreeCookie: 'hasAgreeCookie',
      hotelHotelWebSiteUrl: 'hotel/hotelWebSiteUrl',
      isOwlTingDomain: 'isOwlTingDomain',
      hotelDisplaySetting: 'hotel/hotelDisplaySetting',
      hotelTrackSetting: 'hotel/hotelTrackSetting'
    }),
    dayjsLangParse () {
      let result = this.selectedLanguage.toLowerCase()
      switch (result) {
        case 'tw':
        case 'zh-tw':
        case 'zh_tw':
          result = 'zh-tw'
          break
        case 'ja':
        case 'ja-jp':
        case 'ja_jp':
          result = 'ja'
          break
        case 'en':
        case 'en-us':
        case 'en_us':
        default:
          result = 'en'
          break
      }
      return result
    },
    isLoadingHotel: function () {
      return this.$store.state.hotel.isLoadingHotel
    },
    isInitDone: function () {
      return typeof this.hotelHotel.contact !== 'undefined' && !_.isEmpty(this.hotelHotel.contact)
    },
    coverImages: function () {
      let result = [...this.hotelHotel.cover_images.hotels]
      let roomsImages = []
      _.each(this.hotelHotel.cover_images.rooms, function (room) {
        roomsImages = _.concat(roomsImages, room.images[0])
      })
      result = result.concat(roomsImages)
      return result
    },
    hasPlan: function () {
      return !_.isEmpty(this.orderPlans) || !_.isEmpty(this.orderAddons)
    },
    displayFooterMarginBottom: function () {
      return this.hasPlan && this.windowWidth < 768 && this.$route.meta.footerMargin !== false
    },
    fbChatLanguageList: function () {
      return {
        'zh_TW': 'zh_TW',
        'en': 'en_US',
        'ja': 'ja_JP'
      }
    },
    fbChatLanguage: function ({ fbChatLanguageList }) {
      return fbChatLanguageList[this.selectedLanguage]
    },
    metaImagePath: function () {
      const coverImage = this.coverImages[0] || ''
      const relativeRule = /^(\/\/)/
      return relativeRule.test(coverImage) ? `https:${coverImage}` : coverImage
    },
    ogUrl: function () {
      return `${window.location.origin}/${this.$route.params.hotelId}?lang=${this.selectedLanguage}`
    },
    jsonld: function () {
      return [{
        '@context': 'https://schema.org',
        '@type': 'Hotel',
        name: this.metaTitle(),
        address: this.hotelHotel.address,
        image: this.metaImagePath,
        url: this.ogUrl,
        geo: {
          '@type': 'GeoCoordinates',
          latitude: this.hotelHotel.address_location?.split(',')[0] || '',
          longitude: this.hotelHotel.address_location?.split(',')[1] || ''
        },
        description: this.metaDescription(),
        telephone: this.hotelHotel.contact.phone
      }, {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: this.metaTitle(),
        url: this.ogUrl,
        alternateName: [
          this.hotelHotel.name || '',
          this.$t('owlTingBlockChainBookingSystemJsonLd')
        ]
      }]
    }
  },
  methods: {
    ...mapActions([
      'hotel/fetchHotelRoomsType',
      'hotel/fetchRooms',
      'goStep',
      'locateCustomerIP'
    ]),
    ...mapMutations([
      'WINDOW_RESIZE',
      'SET_CURRENCY_CONVERSION',
      'SET_CURRENCY_SELECTABLE',
      'SET_SUPER',
      'SET_COOKIE'
    ]),
    getImgUrl (path) {
      path = path.replace('@', '.')
      let lang_ = this.lang
      switch (this.lang) {
        case 'en':
        case 'tw':
        case 'ja':
          break
        default:
          lang_ = 'en'
          break
      }
      const path2 = path.replace(/{lang}/g, `_${lang_}`)
      if ($images.keys().includes(path2)) {
        path = path2
      } else {
        path = path.replace(/{lang}/g, '')
      }
      return $images(path)
    },
    openPhotoSwipe: function (info) {
      this.$refs.photoSwipe.open(info)
      this.hotelCoverShow = true
    },

    photoSwipeCloseHandler () {
      this.hotelCoverShow = false
    },

    metaTitle: function (isJsonLd = true) {
      const parentSite = isJsonLd ? `${this.$t('owlTingBlockChainBookingSystemJsonLd')}` : `${this.$t('owlTingBlockChainBookingSystem')}`
      const hotelName = (this.hotelHotel.name && ` - ${this.hotelHotel.name}`) || ''
      let metaTitle = `${this.$t('websiteBooking')}${hotelName}`
      if (this.isOwlTingDomain) {
        metaTitle += ` | ${parentSite}`
      }
      return metaTitle
    },

    metaDescription: function (isJsonLd = true) {
      const parentSiteDescription = isJsonLd ? `${this.$t('owlTingOwlNestDescriptionJsonLd')}` : `${this.$t('owlTingOwlNestDescription')}`
      if (this.isOwlTingDomain) {
        return this.hotelHotel.description || parentSiteDescription
      }
      return this.hotelHotel.description
    }
  },
  created () {
    // locate customer ip
    this.locateCustomerIP()
    // VueFacebookPixel
    if (window.fbq) {
      const hotelPixelId = this.hotelTrackSetting.fb_pixel_id
      // 受限於 FB Pixel 同網域的事件數量限制，只能有一個 Pixel ID
      if (hotelPixelId) {
        window.fbq('init', hotelPixelId)
      } else {
        window.fbq('init', '1171061846253080')
      }
      window.fbq('track', 'PageView')
    }

    const chatHotel = fbChatSet.find(hotel => hotel.hotelId === this.hotelHotel.id)
    if (chatHotel) {
      this.messageButtons.push({
        link: chatHotel.link,
        background: `url(${this.getImgUrl('@/assets/img/fb_msg.png')})`
      })
    }

    let currencyScript = document.createElement('script')
    currencyScript.setAttribute('src', `https://www.owlting.com/experiences/module/currency_rate.js?v=${Date.now()}`)
    document.head.appendChild(currencyScript)
    currencyScript.onload = () => {
      if (window.c_r) {
        const currency = _.concat(window.c_r, window.c_r_exception)
        this['SET_CURRENCY_CONVERSION'](currency)
        this['SET_CURRENCY_SELECTABLE'](window.c_r)
      }
    }

    this['hotel/fetchHotelRoomsType']()
    this['hotel/fetchRooms']()
    this.WINDOW_RESIZE(window.innerWidth)

    window.addEventListener('resize', e => {
      this.WINDOW_RESIZE(e.target.innerWidth)
    })
    let key = ''
    const that = this
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 83) {
        if (key === '') {
          key += 's'
        } else {
          key = ''
        }
      }
      if (event.keyCode === 85) {
        if (key === 's') {
          key += 'u'
        } else {
          key = ''
        }
      }
      if (event.keyCode === 80) {
        if (key === 'su') {
          key += 'p'
        } else {
          key = ''
        }
      }
      if (event.keyCode === 69) {
        if (key === 'sup') {
          key += 'e'
        } else {
          key = ''
        }
      }
      if (event.keyCode === 82) {
        if (key === 'supe') {
          key += 'r'
          that.SET_SUPER(!that.isSuper)
          key = ''
        } else {
          key = ''
        }
      }
    })

    // check cookie
    const hasAgreeCookie = document.cookie.includes('owlnestBE-agree-cookie')
    if (hasAgreeCookie) {
      this.SET_COOKIE()
    }
  }
}
</script>

<style lang="scss">
.debug {
  position: fixed;
  top: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  z-index: 10000;
  white-space: pre-line;
  max-height: calc(100vh - 10px);
  overflow: auto;
  pointer-events: none;
}

[class*=hotel-] {
  position: relative;
  z-index: 2;

  &.z-index-up {
    z-index: 9;
  }
}

.hotel-navbar {
  z-index: 10;
}

.hotel-main {
  position: relative;
  z-index: 4;

  &.show {
    z-index: 11;

    #hotel-sub-nav {
      z-index: 12;
    }
  }
}

.hotel-cover {
  z-index: 3;

  &.show {
    z-index: 15;
  }
}
</style>

<style lang="sass">
body
  @apply antialiased
  font-family: 'Microsoft JhengHei', sans-serif

.os-tag
  margin-top: 0px !important

footer
  .footer
    &__hotel-name
      i
        @apply inline-block
        transition: .3s ease
      @media not all and (pointer: coarse)
        .footer__hotel-website:hover
          i
            transform: translateX(5px)

    &__contacts
      &__way
        @apply text-grey-darker no-underline inline-flex items-center
        &:not(:last-of-type)
          @apply mb-16
        i
          @apply mr-8 text-lg

  &.mobile-has-order
    margin-bottom: 60px

button, .btn
  touch-action: manipulation

.fb_dialog_content > iframe
  display: none !important
.fb-customerchat, .fb_dialog_content
  display: none !important
.fb_iframe_widget, .fb_invisible_flow
  iframe
    bottom: 72px !important
    @screen sm
      bottom: 4px !important
      right: 4px !important
</style>
